import React from 'react'
import {Inner} from '../components/responsive/'

export default props => <Inner
  lineHeight={props.lineHeight}
  fontWeight={props.fontWeight}
  as='p'
  bold={props.bold}
  margin={props.margin}
  color='#7f7f7f'>
{props.children}
</Inner>
