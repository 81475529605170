import React from 'react'
import {Inner} from '../components/responsive/'
import Entry from '../components/entry'

export default props => <div>
  <Inner
    color='#0098cb'
    size='1.5em'
    marginTop='2em'
    bold
    center
  >
  DrupalCamp DEN 2022 OSAKA ONLINE<br/>
  2022年1月29日(土)
  </Inner>
  <Inner
    color='#0098cb'
    size='1.2em'
    marginTop='0.5em'
    paddingBottom='2em'
    bold
    center
  >
  </Inner>
  <Entry/>
</div>
